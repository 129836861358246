<template>
  <IxWmsLayer
    :url="url"
    :layers="layer"
    :clip-features="features"
  />
</template>

<script>
import {mapState} from 'vuex'
import {config} from 'src/js/infrastructure'

import IxWmsLayer from '@components/map/IxWmsLayer'

export default {
  components: {
    IxWmsLayer
  },
  props: {
    image: {
      type: Object,
      default: null
    },
    channel: {
      type: String,
      default: 'ndvi',
      validator: value => /ndvi|cir|cloud|clm/.test(value)
    }
  },
  computed: {
    ...mapState('fieldRecordSystem/biomassMaps', {
      borderGeometry: state => state.data.borderGeometry
    }),
    features () {
      return [{
        type: 'Feature',
        properties: {},
        geometry: this.borderGeometry
      }]
    },
    baseUrl () {
      return config.sentinelHubPreviewInstances[this.image.dataSource]
    },
    url () {
      const {recordingDate} = this.image

      return `${this.baseUrl}?TIME=${recordingDate}/${recordingDate}`
    },
    layerPrefix () {
      switch (this.image.dataSource) {
      case 'Sentinel2': return 'S2_L1C_'
      case 'Sentinel2AtmosphericallyCorrected': return 'S2_L2A_'
      default: return ''
      }
    },
    layerSuffix () {
      switch (this.channel) {
      case 'ndvi': return 'BIOMASS'
      case 'cloud': return 'CLOUD'
      case 'clm': return 'CLMCLP'
      case 'cir': return 'CIR'
      default: throw new Error('invalid channel')
      }
    },
    layer () {
      return this.layerPrefix + this.layerSuffix
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
