<template lang="html">
  <FrsModuleRoot
    :module="module"
    :module-path="['fieldRecordSystem', 'biomassMaps']"
    :translation-namespaces="['bmm']"
    :setup="loadPrerequisiteData"
  >
    <FrsRouterBackLink target="fieldDashboard" />
    <BiomassMapOrder />

    <template #left>
      <SatelliteImageList />
    </template>
  </FrsModuleRoot>
</template>

<script>
import {mapActions} from 'vuex'

import FrsModuleRoot from '@frs/components/base/FrsModuleRoot'
import FrsRouterBackLink from '@frs/components/base/FrsRouterBackLink'

import BiomassMapOrder from './BiomassMapOrder'
import SatelliteImageList from './SatelliteImageList'

export default {
  components: {
    FrsModuleRoot,
    FrsRouterBackLink,
    BiomassMapOrder,
    SatelliteImageList
  },
  computed: {
    module () {
      return () => import('@store/modules/field-record-system/biomass-maps')
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/biomassMaps', [
      'loadPrerequisiteData'
    ])
  }
}
</script>

<style lang="scss" scoped>
</style>
