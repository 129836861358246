<template lang="html">
  <div class="map-order">
    <HelpBox>
      <IxRes>frs.biomassMaps.manual</IxRes>
    </HelpBox>
    <HelpBox>
      <IxRes>frs.biomassMaps.paymentInformation</IxRes>
    </HelpBox>

    <LabelAndMessage>
      <template #label>
        <h3><IxRes>frs.biomassMaps.legend</IxRes></h3>
      </template>
      <GradientScale :colors="colors">
        <template #startLabel>
          <span>min.</span>
        </template>
        <template #endLabel>
          <span>max.</span>
        </template>
      </GradientScale>
    </LabelAndMessage>

    <RedBorderPreview
      v-model="buffer"
      :enabled.sync="useRedBorder"
      :original-border="borderGeometry"
      @error="$emit('error', $event)"
    />
    <div class="button-container">
      <IxButton
        large icon="arrow-circle-o-right"
        colored
        :disabled="!satelliteImageSelected || calculationStarted" @click="calculateMap"
      >
        <IxRes>frs.biomassMaps.calculateMap</IxRes>
      </IxButton>
      <IxButton large class="cancel-button" @click="cancel">
        <IxRes>frs.common.cancel</IxRes>
      </IxButton>
    </div>
    <div class="button-container">
      <IxButton v-if="calculationStarted" large @click="openMapManagement">
        <IxRes>frs.biomassMaps.openMapManagement</IxRes>
      </IxButton>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import {notifications} from 'src/js/infrastructure'

import MirrorRouteMixin from '@frs/mixins/MirrorRouteMixin'
import DisableDefaultMapInteractionsMixin from '@frs/mixins/DisableDefaultMapInteractionsMixin'

import RedBorderPreview from '@components/geometry/RedBorderPreview'
import GradientScale from '@components/GradientScale'
import HelpBox from '@components/help/HelpBox'
import IxButton from '@components/IxButton'
import LabelAndMessage from '@components/forms/input-base/LabelAndMessage'
import {mapFormFields} from '@helpers/vuex'

export default {
  components: {
    LabelAndMessage,
    GradientScale,
    HelpBox,
    IxButton,
    RedBorderPreview
  },
  mixins: [
    MirrorRouteMixin,
    DisableDefaultMapInteractionsMixin
  ],
  computed: {
    ...mapFormFields('fieldRecordSystem/biomassMaps', [
      'ui.calculationStarted',
      'ui.buffer',
      'ui.useRedBorder'
    ]),
    ...mapState('fieldRecordSystem/biomassMaps', {
      selectedImageId: state => state.data.selectedImageId,
      borderGeometry: state => state.data.borderGeometry
    }),
    colors () {
      return ['#d3ff47', '#0b4d07']
    },
    satelliteImageSelected () {
      return this.selectedImageId !== null
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/biomassMaps', [
      'calculateBiomassMap'
    ]),
    calculateMap () {
      this.calculationStarted = true
      this.calculateBiomassMap()
      notifications.success(this.$i18n.translate('frs.biomassMaps.calculatingMap'))
    },
    cancel () {
      this.$router.push({name: 'fieldDashboard', params: {...this.$route.params}})
    },
    openMapManagement () {
      this.$router.push({name: this.createParallelRoute('mapManagement'), params: {...this.$route.params}})
    }
  }
}
</script>

<style lang="scss" scoped>
.map-order{
  padding: 0.5em;
}
.cancel-button {
  margin-left: 5px
}
.button-container {
  margin-top: 15px;
  display: flex;
}
</style>
