<template lang="html">
  <div class="channel-selector">
    <label>
      <IxRes>frs.bmm.labels.channelSelector</IxRes>
    </label>

    <div class="btn-group btn-group-justified">
      <div
        v-for="channel in availableChannels" :key="channel"
        class="btn-group"
      >
        <ToggleButton
          v-model="modelProxy"
          :value="channel"
          :disabled="!image"
          required
        >
          <IxRes>frs.bmm.labels.channel_{{ channel }}</IxRes>
        </ToggleButton>
      </div>
    </div>
  </div>
</template>

<script>
import ToggleButton from '@components/ToggleButton'

import {modelProxy} from '@helpers/vuex'

export default {
  components: {
    ToggleButton
  },
  props: {
    value: String,
    image: {
      type: Object,
      default: null
    }
  },
  computed: {
    modelProxy,
    availableChannels () {
      return ['ndvi', 'cir', 'cloud', 'clm']
    }
  }
}
</script>

<style lang="scss" scoped>
.channel-selector {
  display: flex;
  flex-direction: column;
}
</style>
