<template lang="html">
  <div class="satellite-image-container">
    <h2>
      <IxRes>bmm.titles.satelliteImages</IxRes>
    </h2>
    <VueDateTimePicker
      v-model="startDate"
      :min="minDate"
      :max="maxDate"
    >
      <IxRes slot="label">bmm.labels.satelliteImageStartDate</IxRes>
      <IxRes slot="description">bmm.hints.startDateDescription</IxRes>
    </VueDateTimePicker>

    <div class="images-info">
      <IxRes :context="{from: startDate.format('L'), to: maxDate.format('L')}">bmm.hints.searchPeriod</IxRes>
      <HelpBox>
        <IxRes v-if="satelliteImages.length">bmm.hints.imageSelection</IxRes>
        <IxRes v-else :context="{start: startDate.format('L'), end: maxDate.format('L')}">bmm.notifications.noImages</IxRes>
      </HelpBox>
      <IxRes :context="{count: satelliteImages.length}">bmm.hints.countOfImages</IxRes>
      <IxRes :context="{count: ignoredImageCount}">bmm.hints.ignoredImages</IxRes>
    </div>

    <ChannelSelector v-model="shownChannel" :image="selectedImage" />
    <SatImageLayer v-if="selectedImage" :image="selectedImage" :channel="shownChannel" />

    <FrsCurrentFieldBorderLayer />

    <RecycleScroller
      v-slot="{item}" :items="satelliteImages"
      class="scroller" :item-size="64"
      key-field="id"
    >
      <ListItem :image="item" :selected="item.id === selectedImageId" @select="selectedImageId = $event" />
    </RecycleScroller>

    <FrsLoadingIndicator :requests="['get.ndviPreview']" />
  </div>
</template>

<script>
import {mapState, mapActions, mapGetters} from 'vuex'
import moment from 'moment'
import {mapFormFields} from '@helpers/vuex'

import ListItem from './widgets/ListItem'
import ChannelSelector from './widgets/ChannelSelector'

import VueDateTimePicker from '@components/forms/VueDateTimePicker'
import HelpBox from '@components/help/HelpBox'

import FrsCurrentFieldBorderLayer from '@frs/components/base/FrsCurrentFieldBorderLayer'
import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'
import SatImageLayer from '@frs/components/biomass-maps/SatImageLayer'

export default {
  components: {
    ListItem,
    FrsLoadingIndicator,
    VueDateTimePicker,
    HelpBox,
    SatImageLayer,
    FrsCurrentFieldBorderLayer,
    ChannelSelector
  },
  data () {
    return {
      shownChannel: 'ndvi'
    }
  },
  computed: {
    ...mapFormFields('fieldRecordSystem/biomassMaps', [
      'data.selectedImageId',
      'ui.startDate'
    ]),
    ...mapState('fieldRecordSystem/biomassMaps', {
      satelliteImages: state => state.data.satelliteImages,
      ignoredImageCount: state => state.data.ignoredImageCount
    }),
    ...mapGetters('fieldRecordSystem/biomassMaps', [
      'selectedImage'
    ]),
    minDate () {
      return moment().subtract(12, 'months').startOf('d')
    },
    maxDate () {
      return moment().startOf('d')
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/biomassMaps', [
      'loadSatelliteImages'
    ])
  },
  watch: {
    startDate (date) {
      if (date) this.loadSatelliteImages()
    }
  }
}
</script>

<style lang="scss" scoped>
.satellite-image-container{
  padding: 0 8px;
  height: 100%;
  display: flex;
  flex-direction: column;

  .images-info {
    display: flex;
    flex-direction: column;
    margin: 5px 0;
    border-bottom: 1px solid lightgrey;
    .alert ::v-deep {
      margin: 5px 0;
    }
  }

  .scroller {
    margin-top: 8px;
    flex:1;
  }
}
</style>
