<template>
  <div>
    <ListCard show-avatar :selected="selected" @click.native="$emit('select', image.id)">
      <template #avatar>
        <FontAwesomeIcon class="icon" :icon="icon" />
      </template>
      <template #title>
        <IxRes :context="{date: $i18n.format(image.recordingDate, 'date')}">
          bmm.preview.titles.date
        </IxRes>
      </template>
      <template #subtitle>
        <IxRes
          :context="{
            ndviMean: `${$i18n.format(image.ndviMean, 'f2')}`,
            cloudMean: `${$i18n.format(image.cloudMean, 'percent')} ⛅`
          }"
        >
          bmm.preview.titles.ndviMean
        </IxRes>
      </template>
    </ListCard>
  </div>
</template>

<script>
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {faImage} from '@fortawesome/free-regular-svg-icons'

import ListCard from '@components/card/ListCard'

export default {
  components: {
    FontAwesomeIcon,
    ListCard
  },
  props: {
    image: {
      type: Object,
      required: true
    },
    selected: Boolean
  },
  computed: {
    icon () {
      return faImage
    }
  }
}
</script>

<style lang="scss" scoped>
.icon {
  font-size: 2.2em;
}
</style>
